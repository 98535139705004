import React from "react";

const Services = () => {
  return (
    <section className="tf-section montono" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="block-text center">
              <h2
                className="sub-title mb-10"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                About the Senior Benefits Agency team
              </h2>
              <h3
                className="title mb-28"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Modern software and industry-best resources for our agents to
                succeed.
              </h3>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-xl-5 col-md-6">
            <div className="image-box  mb-120">
              <div className="card">
                <div className="card-caracteristica"></div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="text-area">
              <div className="block-text pt-12">
                <h3
                  className="desc mb-58"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <span>
                    <b>Join Our Team at Senior Benefits Agency!</b>
                  </span>{" "}
                  <br />
                  <br />
                  Are you passionate about making a meaningful difference in
                  people's lives? At Senior Benefits Agency, led by Josh Jensen,
                  we are at the forefront of ensuring the well-being of
                  thousands of clients across the Northeast, Florida, and
                  California. With a dedicated team of over 200 agents, we
                  specialize in Medicare Advantage, Medicare Supplement, and
                  retirement protection plans.
                  <span>
                    {" "}
                    <br />
                    <br />
                    <b>Join Us Today</b>
                  </span>{" "}
                  <br />
                  <br />
                  Embark on a rewarding career where your efforts directly
                  impact the lives of others. Discover the opportunities waiting
                  for you at Senior Benefits Agency and become part of a team
                  dedicated to delivering exceptional value to our clients every
                  day.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
