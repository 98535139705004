import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

const Slider = () => {
  const { t } = useTranslation();

  return (
    <section className="tf-section hero-slider">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-md-12 col-12">
            <div className="block-text up pt-24">
              <h1 className="title mb-26" data-aos="fade-up">
                Are you ready for a career that will change your life?
              </h1>
              <p className="desc2 mb-43" data-aos="fade-up">
                <b>
                  Transform your life by watching our three minute video below!
                </b>
              </p>

              <a
                href="https://apply.workable.com/senior-benefits-agency/?lng=en"
                className="btn-action style-2"
                data-aos="fade-up"
                data-aos-duration="1200"
                target="_blank"
                rel="noreferrer"
              >
                START MY CAREER NOW
              </a>
            </div>
          </div>
        </div>
        <div className="row-extra">
          <div className="reproductor">
            <ReactPlayer
              url={require("../../assets/images/video.mp4")}
              width="100%"
              height="100%"
              controls
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
