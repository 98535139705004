import React from "react";
import image1 from "../../assets/images/fotos/caracteristica1.webp";
import image2 from "../../assets/images/fotos/caracteristica2.webp";
import image3 from "../../assets/images/fotos/caracteristica3.webp";
import image4 from "../../assets/images/fotos/caracteristica4.webp";
import image5 from "../../assets/images/fotos/caracteristica5.webp";
import image6 from "../../assets/images/fotos/caracteristica6.webp";
import image7 from "../../assets/images/fotos/caracteristica7.webp";
import { FaCheck } from "react-icons/fa";

const TicketHome = () => {
  return (
    <div className="ticket">
      <section className="tf-section section-ticket">
        <div className="container">
          <div className="row row-extra2">
            <div className="col-md-12">
              <div className="block-text center">
                <h2
                  className="sub-title mb-10"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Why Choose a Career with Us?
                </h2>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image1} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title sub-ticket"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {" "}
                  <FaCheck className="icon-check" />
                  {"  "}
                  Impactful Work
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Join a team that prioritizes building strong, lasting
                  relationships with clients and agents alike, providing
                  personalized and attentive service that truly makes a
                  difference.
                </p>

                <p
                  className="desc3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image2} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title sub-ticket"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {" "}
                  <FaCheck className="icon-check" /> Support and Development
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  We are committed to your success. Receive comprehensive
                  training and continuous support tailored to your individual
                  needs. At Senior Benefits Agency, we believe in coaching our
                  agents to succeed, empowering them with the tools and
                  knowledge to excel in their careers.
                </p>

                <p
                  className="desc4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image3} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <FaCheck className="icon-check" /> Lifetime Residual Income
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {"  "}Unlike traditional sales roles, enjoy the advantage of
                  earning lifetime residual income. Benefit from getting paid
                  multiple times on a sale, not just once. This ensures
                  long-term financial stability and rewards your ongoing
                  efforts, setting us apart from roles like Life Insurance
                  Agents.{" "}
                </p>

                <p
                  className="desc3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
          </div>
          <div className="row row-extra2">
            <div className="col-md-12"></div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image4} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title sub-ticket"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {" "}
                  <FaCheck className="icon-check" />
                  {"  "}
                  Collaborative Environment
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Enjoy everything you need in one place as an agent. Benefit
                  from a Medicare CRM, dedicated customer service that acts as
                  an extension of your service, accelerated contracting and
                  licensing processes, and all the marketing tools necessary to
                  succeed.
                </p>

                <p
                  className="desc3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image5} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title sub-ticket"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <FaCheck className="icon-check" />
                  {"  "}
                  Access to Lead System
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Gain access to our state-of-the-art lead generation systems.
                  We invest in quality leads through advanced techniques
                  including direct mail marketing, social media strategies, and
                  more. This ensures you have the best opportunities to connect
                  with clients and grow your business effectively.
                </p>

                <p
                  className="desc4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 box-ticket ">
              <div
                className="image-box-ticket"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="card-ticket">
                  <img className="ticket-img" src={image6} alt="Landing" />
                </div>
              </div>
              <div className="block-text center">
                <h3
                  className="sub-title"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <FaCheck className="icon-check" />
                  {"  "} Opportunity to Build a Team
                </h3>
                <p
                  className="desc2 margin-top"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Take advantage of our full-scale, hands-off recruiting
                  resources. Aspire to leadership? You can grow your career by
                  recruiting and nurturing your own team, leveraging our robust
                  support systems.
                </p>

                <p
                  className="desc3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TicketHome;
