import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import "./header.scss";
import logo from "../../assets/images/logo.webp";

const Header = () => {
  const { pathname } = useLocation();

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <header
      id="header_main"
      className={`header js-header ${scroll ? "is-fixed" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="header__body d-flex justify-content-between">
              <div className="header__logo">
                <Link to="/">
                  <img id="site-logo" src={logo} alt="logo" />
                </Link>
              </div>

              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <a
                    href="/#reproductor-2"
                    className="btn-action2 style-3"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    HELP ME GET LICENSED
                  </a>
                  <a
                    href="https://apply.workable.com/senior-benefits-agency/?lng=en"
                    className="btn-action style-2"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    APPLY HERE
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
