import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import { FaPhoneVolume } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer__body">
              <h3 className="sub-title">Contact</h3>

              <ul>
                <li data-aos="fade-up">
                  <div className="icon">
                    <MdEmail className="icon-call" />
                  </div>
                  <div className="info">
                    <p className="fs-18"> careers@seniorbenefitsagency.net</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="footer_bottom">
              <p className="fs-16">
                Copyright © 2024 Senior Beneficts Agency. Diseñado por{" "}
                <a href="https://escalewebs.com/">ESCALE</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </>
  );
};

export default Footer;
