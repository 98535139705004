import React from "react";
import dataServices from "../assets/fake-data/data-services";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Services from "../components/layouts/Services";
import Slider from "../components/slider/Slider";
import { LanguageProvider } from "../context/languageContext";
import TicketHome from "../components/layouts/TicketHome";
import UltimoVideo from "../components/layouts/UltimoVideo";
const Home = () => {
  return (
    <div className="home-3">
      <LanguageProvider>
        <Header />
        <Slider />
        <Services data={dataServices} />
        <TicketHome />
        <UltimoVideo />
        <Footer />
      </LanguageProvider>
    </div>
  );
};

export default Home;
