import React from "react";
import ReactPlayer from "react-player";

const UltimoVideo = () => {
  return (
    <section className="tf-section hero-slider" id="reproductor-2">
      <div className="container">
        <div className="row mt-61">
          <div className="col-xl-12 col-md-12 col-12">
            <div className="block-text">
              <h1 className="title" data-aos="fade-up">
                {" "}
                <span>How to become a licensed agent</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="row-extra">
          <div className="reproductor2">
            <ReactPlayer
              className="reproductor2-caja"
              url={require("../../assets/images/video2.mp4")}
              width="100%"
              height="100%"
              controls
            />
          </div>
          <div className="block-text up pt-24">
            <a
              href="https://apply.workable.com/senior-benefits-agency/?lng=en"
              className="btn-action style-2"
              data-aos="fade-up"
              data-aos-duration="1200"
              target="_blank"
              rel="noreferrer"
            >
              LAUNCH MY CAREER TODAY
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UltimoVideo;
